<template>
    <div class="cate">
          <Header url="cate2"></Header>
             <div class="catebg">
                   <img src="../assets/img/allSpc.jpg" />
                   <div class="bgtitle">一级分类名称</div>
             </div>
             <div class="goodslist">
                    <router-link :to="{ path: '/goodsList', query: { cid: index}}" class="item" v-for="(item,index) in goodslist" :key="index">
                        <img src="../assets/img/1.png" alt="">
                        <div class="imgtitle line1">二级分类</div>
                    </router-link>
              </div>
          <Footer />
    </div>
</template>
<style>
.cate .catebg{position: relative}
.cate .bgtitle{position: absolute;    left: 50%;color: #fff;font-size: 18px;  top: 50%;  transform: translate(-50%, -50%);}
.cate  .goodslist{width: 1260px;overflow: hidden;margin: auto;}
.cate  .goodslist .item{position: relative;width:295px;height:194px;float: left;margin: 10px; cursor: pointer;}
.cate  .goodslist .item .imgtitle{position:absolute;bottom: 0;width: 100%;height: 40px;line-height: 40px;background: #000000ab;color: #fff;text-align: center;}
</style>
<script>
import Header from '@/components/header'
import Footer from '@/components/footer'


export default {
    name: 'Cate2',
    components:{Header,Footer},
    data() {
        return {
          id:0,
          goodslist:[1,2,3,4,5,6,1,2,3,4,5,6,1,2,3,4,5,6,1,2,3,4,5,6],
          current:-1,
        }
     },
     watch: {
      '$route' (to) {
          this.id = to.query.cid;
      }
	} ,
    mounted(){ this.id = this.$route.query.cid;},
    methods: {

    }
}
</script>
